import React from "react";
import ycombinator from "../assets/ycombinator.png";
import antler from "../assets/antler.png";
import harvard_management_company from "../assets/harvard_management_company.png";
import pravega from "../assets/pravega.png";
import google from "../assets/google.png";
import goodwater from "../assets/goodwater8.png";

const LogoClouds = () => {
  return (
    <div className="bg-white py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <h2 className="text-center text-lg font-semibold leading-8 text-gray-900">
          Backed by 
        </h2>
        <div className="mx-auto mt-10 grid max-w-lg grid-cols-4 items-center gap-x-8 gap-y-10 sm:max-w-xl sm:grid-cols-6 sm:gap-x-10 lg:mx-0 lg:max-w-none lg:grid-cols-5">
          <img
            className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
            src={ycombinator}
            alt="Transistor"
            style={{ width: 170, height: 60 }}
          />

          <img
            className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
            src={antler}
            alt="Reform"
            width={158}
            height={48}
          />
          <img
            className="col-span-2 max-h-12 w-full object-contain lg:col-span-1"
            src={harvard_management_company}
            alt="Tuple"
            width={158}
            height={48}
          />
          <img
            className="col-span-2 max-h-12 w-full object-contain sm:col-start-2 lg:col-span-1 "
            src={goodwater}
            alt="SavvyCal"
            // style={{borderRadius: "10%" }}
          />
          <img
            className="col-span-2 col-start-2 max-h-12 w-full object-contain sm:col-start-auto lg:col-span-1"
            src={google}
            alt="Statamic"
            width={158}
            height={48}
          />
          {/* <img
            className="col-span-2 col-start-2 max-h-12 w-full object-contain sm:col-start-auto lg:col-span-1"
            src={goodwater}
            alt="Statamic"
            width={158}
            height={48}
          /> */}
        </div>
      </div>
    </div>
  );
};

export default LogoClouds;
