import {
  CloudArrowUpIcon,
  LockClosedIcon,
  ServerIcon,
  ClockIcon,
  ChartBarSquareIcon,
  AtSymbolIcon,
  RocketLaunchIcon,
} from "@heroicons/react/20/solid";
import DemoGif from "../assets/demo7.gif";

const features = [
  {
    name: "Push to deploy.",
    description:
      " Conduct research and map out the top priorities of hundreds of your prospects in just minutes, aligning them with your offerings.",
    icon: ClockIcon,
  },
  {
    name: "SSL certificates.",
    description:
      "Identify the relevant points of contact for each initiative and automate your engagement with them on LinkedIn.",
    icon: ChartBarSquareIcon,
  },
  {
    name: "Database backups.",
    description:
      "Craft highly personalized emails to reach out to hundreds of prospects in a matter of minutes.",
    icon: RocketLaunchIcon,
  },
];


export default function Demo() {
  return (
    <div className="overflow-hidden bg-white py-24 sm:py-32 flex justify-center items-center">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
          <div className="lg:pr-8 lg:pt-4 lg:ml-[-6rem] flex items-center">
            {" "}
            {/* Updated class to center items */}
            <div className="lg:max-w-lg">
              <p className="mt-2 text-xl sm:text-3xl font-bold tracking-tight text-gray-900">
                How do we do this?
              </p>
              <dl className="mt-8 max-w-md space-y-6 text-base leading-7 text-gray-600 sm:max-w-xl lg:max-w-none">
                {features.map((feature) => (
                  <div key={feature.name} className="flex items-center">
                    <span className="flex-shrink-0">
                      <feature.icon className="h-6 w-6 text-indigo-600" />
                    </span>
                    <p className="ml-6 text-base sm:text-lg text-gray-600">
                      {feature.description}
                    </p>
                  </div>
                ))}
              </dl>
            </div>
          </div>
          <img
            src={DemoGif}
            alt="Product screenshot"
            className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-40 lg:-ml-40"
            width={2432}
            height={1442}
          />
        </div>
      </div>
    </div>
  );
}

