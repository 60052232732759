import React from "react";
import styles from ".././terms.module.css";

const Terms = () => {
  return (
    <div style={{ margin: "50px" }}>
      <p className={styles.c7}>
        <span className={`${styles.c5} ${styles.c6}`}>Terms of Service</span>
      </p>
      <p className={styles.c2}>
        <span className={styles.c1}></span>
      </p>
      <p className={styles.c4}>
        <span className={styles.c1}>Last updated on August 19, 2024</span>
      </p>
      <p className={styles.c2}>
        <span className={styles.c1}></span>
      </p>
      <p className={styles.c10}>
        <span className={`${styles.c5} ${styles.c6}`}>
          ZEALTHLIFE PTE. LTD. Application Terms of Service
        </span>
      </p>
      <p className={styles.c2}>
        <span className={styles.c3}></span>
      </p>
      <p className={styles.c4}>
        <span className={styles.c0}>
          Welcome to the TrillionSale Application
        </span>
      </p>
      <p className={styles.c2}>
        <span className={styles.c3}></span>
      </p>
      <p className={styles.c4}>
        <span className={styles.c3}>
          These Terms of Service (&quot;Terms&quot;) are a legal agreement
          between you and ZEALTHLIFE PTE. LTD. (&quot;Company&quot;,
          &quot;we&quot;, &quot;us&quot;, or &quot;our&quot;), a Singapore-based
          entity, governing your access to and use of the application hosted at
          https://app.trillionsale.com (&quot;Application&quot;). By accessing
          or using the Application, you agree to be bound by these Terms and our
          Privacy Policy. If you do not agree to these Terms, you may not access
          or use the Application.
        </span>
      </p>
      <p className={styles.c2}>
        <span className={styles.c3}></span>
      </p>
      <p className={styles.c4}>
        <span className={styles.c0}>1. Eligibility</span>
      </p>
      <p className={styles.c2}>
        <span className={styles.c3}></span>
      </p>
      <p className={styles.c4}>
        <span className={styles.c3}>
          You must be at least 18 years old to use the Application. By agreeing
          to these Terms, you represent and warrant that you are of legal age to
          form a binding contract with the Company.
        </span>
      </p>
      <p className={styles.c2}>
        <span className={styles.c3}></span>
      </p>
      <p className={styles.c4}>
        <span className={styles.c0}>2. Account Registration</span>
      </p>
      <p className={styles.c2}>
        <span className={styles.c3}></span>
      </p>
      <p className={styles.c4}>
        <span className={styles.c3}>
          To access certain features of the Application, you may be required to
          create an account. You agree to provide accurate, current, and
          complete information during the registration process and to update
          such information to keep it accurate, current, and complete.
        </span>
      </p>
      <p className={styles.c2}>
        <span className={styles.c3}></span>
      </p>
      <p className={styles.c4}>
        <span className={styles.c0}>3. User Conduct</span>
      </p>
      <p className={styles.c2}>
        <span className={styles.c3}></span>
      </p>
      <p className={styles.c4}>
        <span className={styles.c3}>
          You are responsible for all activities that occur under your account.
          You must not use the Application to:
        </span>
      </p>
      <p className={styles.c4}>
        <span className={styles.c3}>
          - Conduct any unlawful or fraudulent activities.
        </span>
      </p>
      <p className={styles.c4}>
        <span className={styles.c3}>
          - Violate any applicable laws, contracts, intellectual property
          rights, or other third-party rights.
        </span>
      </p>
      <p className={styles.c4}>
        <span className={styles.c3}>
          - Upload, transmit, or distribute to or through the Application any
          harmful, illegal, defamatory, or obscene material.
        </span>
      </p>
      <p className={styles.c2}>
        <span className={styles.c3}></span>
      </p>
      <p className={styles.c4}>
        <span className={styles.c0}>4. Intellectual Property</span>
      </p>
      <p className={styles.c2}>
        <span className={styles.c3}></span>
      </p>
      <p className={styles.c4}>
        <span className={styles.c3}>
          The Application and its original content, features, and functionality
          are and will remain the exclusive property of ZEALTHLIFE PTE. LTD. and
          its licensors. Your use of the Application does not grant you any
          ownership rights to the Application or its content.
        </span>
      </p>
      <p className={styles.c2}>
        <span className={styles.c3}></span>
      </p>
      <p className={styles.c4}>
        <span className={styles.c0}>5. Termination</span>
      </p>
      <p className={styles.c2}>
        <span className={styles.c3}></span>
      </p>
      <p className={styles.c4}>
        <span className={styles.c3}>
          We may terminate or suspend your account and bar access to the
          Application immediately, without prior notice or liability, under our
          sole discretion, for any reason whatsoever, including without
          limitation if you breach the Terms.
        </span>
      </p>
      <p className={styles.c2}>
        <span className={styles.c3}></span>
      </p>
      <p className={styles.c4}>
        <span className={styles.c0}>6. Indemnification</span>
      </p>
      <p className={styles.c2}>
        <span className={styles.c3}></span>
      </p>
      <p className={styles.c4}>
        <span className={styles.c3}>
          You agree to defend, indemnify, and hold harmless the Company, its
          officers, directors, employees, and agents, from and against any
          claims, damages, obligations, losses, liabilities, costs, debt, and
          expenses (including but not limited to attorney&#39;s fees) arising
          from your use of and access to the Application, or from or in
          connection with your violation of any term of these Terms.
        </span>
      </p>
      <p className={styles.c2}>
        <span className={styles.c3}></span>
      </p>
      <p className={styles.c4}>
        <span className={styles.c0}>7. Governing Law</span>
      </p>
      <p className={styles.c2}>
        <span className={styles.c3}></span>
      </p>
      <p className={styles.c4}>
        <span className={styles.c3}>
          These Terms shall be governed by and construed in accordance with the
          laws of Singapore, without regard to its conflict of law provisions.
        </span>
      </p>
      <p className={styles.c2}>
        <span className={styles.c3}></span>
      </p>
      <p className={styles.c4}>
        <span className={styles.c0}>8. Changes to Terms</span>
      </p>
      <p className={styles.c2}>
        <span className={styles.c3}></span>
      </p>
      <p className={styles.c4}>
        <span className={styles.c3}>
          We reserve the right, at our sole discretion, to modify or replace
          these Terms at any time. We will provide notice of any changes by
          posting the new Terms on the Application. Your continued use of the
          Application after any changes to these Terms will constitute your
          acceptance of such changes.
        </span>
      </p>
      <p className={styles.c2}>
        <span className={styles.c3}></span>
      </p>
      <p className={styles.c4}>
        <span className={styles.c0}>9. Contact Information</span>
      </p>
      <p className={styles.c2}>
        <span className={styles.c3}></span>
      </p>
      <p className={styles.c4}>
        <span className={styles.c5}>
          If you have any questions about these Terms, please contact us at
          support@trillionsale.com
        </span>
      </p>
    </div>
  );
};

export default Terms;
