// LandingPage.js
import React, { useRef } from "react";
import Hero from "./Hero";
import LogoClouds from "./LogoClouds";
import Problems from "./Problems";
import Features from "./Features";
import Testimonials from "./Testimonials";
import Footer from "./Footer";
import Demo from "./Demo";

const LandingPage = () => {
  const featuresRef = useRef();

  const scrollToFeatures = () => {
    featuresRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div>
      <Hero scrollToFeatures={scrollToFeatures} />
      <Problems />
      <Features featuresRef={featuresRef} />
      <LogoClouds />
      <Demo />
      <Testimonials />
      <Footer />
    </div>
  );
};

export default LandingPage;
