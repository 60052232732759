import React from "react";
import {
  ArrowPathIcon,
  CloudArrowUpIcon,
  FingerPrintIcon,
  LockClosedIcon,
} from "@heroicons/react/24/outline";
import noTime from "../assets/noTime.png";
import dec from "../assets/dec.png";
import loss from "../assets/loss.png";
import down1 from "../assets/down1.png";

const Problems = () => {
  const features = [
    {
      name: "Time consuming research",
      description: "5 hours per client prospect profiling",
      icon: CloudArrowUpIcon,
    },
    {
      name: "Lack of In-Depth Prospect Knowledge ",
      description: " Not manually possible to look at 200+ data points",
      icon: LockClosedIcon,
    },
    {
      name: "Expertise and Personalization Challenge",
      description: "  Avg SQL conversion is <15% for net new accounts",
      icon: ArrowPathIcon,
    },
  ];
  return (
    <div className="px-4 py-16 mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-8 lg:py-20">
      <div className="max-w-xl mb-10 md:mx-auto sm:text-center lg:max-w-2xl md:mb-12">
        <h2 className="mb-6 font-sans text-3xl font-bold leading-none tracking-tight text-gray-900 sm:text-4xl md:mx-auto lg:whitespace-nowrap lg:text-center lg:mx-0 text-center">
          <span className="relative inline-block">
            <svg
              viewBox="0 0 52 24"
              fill="currentColor"
              className="absolute top-0 left-0 z-0 hidden w-32 -mt-8 -ml-20 text-blue-gray-100 lg:w-32 lg:-ml-28 lg:-mt-10 sm:block"
            >
              <defs>
                <pattern
                  id="18302e52-9e2a-4c8e-9550-0cbb21b38e55"
                  x="0"
                  y="0"
                  width=".135"
                  height=".30"
                >
                  <circle cx="1" cy="1" r=".7" />
                </pattern>
              </defs>
              <rect
                fill="url(#18302e52-9e2a-4c8e-9550-0cbb21b38e55)"
                width="52"
                height="24"
              />
            </svg>
            <span className="relative">
              {" "}
              Current state of sales - without Trillionsale
            </span>
          </span>
        </h2>
      </div>
      <div className="grid gap-3 row-gap-4 sm:grid-cols-2 lg:grid-cols-3 ">
        <div
          className="
        // flex flex-col justify-between p-5 border rounded shadow-sm
        "
        >
          <div>
            <div
              className="
              flex items-center justify-center w-16 h-16 mb-4  
              rounded-full
             bg-indigo-50"
              style={{ padding: "10px" }}
            >
              <img
                className=""
                src={noTime}
                alt="logo"
                // style={{ width: "80%", height: "100%" }}
              />
            </div>
            <h6 className="mb-2 font-semibold leading-5">
              Time consuming research
            </h6>
            <p className="mb-3 text-sm text-gray-900">
              5 hours per client prospect profiling
            </p>
          </div>
        </div>
        <div className="flex flex-col justify-between p-5 border rounded shadow-sm">
          <div>
            <div
              className="
              flex items-center justify-center w-16 h-16 mb-4  
              rounded-full
             bg-indigo-50"
              style={{ padding: "10px" }}
            >
              <img
                className=""
                src={loss}
                alt="logo"
                // style={{ width: "80%", height: "100%" }}
              />
            </div>
            <h6 className="mb-2 font-semibold leading-5">
              {" "}
              Lack of In-Depth Prospect Knowledge
            </h6>
            <p className="mb-3 text-sm text-gray-900">
              Not manually possible to look at 200+ data points
            </p>
          </div>
        </div>

        <div className="flex flex-col justify-between p-5 border rounded shadow-sm">
          <div>
            <div
              className="
              flex items-center justify-center w-16 h-16 mb-4  
              rounded-full
             bg-indigo-50"
              style={{ padding: "10px" }}
            >
              <img
                className=""
                src={down1}
                alt="logo"
                // style={{ width: "80%", height: "100%" }}
              />
            </div>
            <h6 className="mb-2 font-semibold leading-5">
              Expertise and Personalization Challenge{" "}
            </h6>
            <p className="mb-3 text-sm text-gray-900">
              Avg SQL conversion is {"<"}15% for net new accounts
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Problems;
