import React from "react";
import styles from ".././privacy.module.css";

const Privacy = () => {
  return (
    <div style={{ margin: "50px" }}>
      <p className={`${styles.b1} ${styles.b5}`}>
        <span className={`${styles.b8} ${styles.b3}`}></span>
      </p>
      <p className={`${styles.b11}`}>
        <span className={`${styles.b8} ${styles.b3}`}>PRIVACY POLICY</span>
      </p>
      <p className={`${styles.b1} ${styles.b5}`}>
        <span className={`${styles.b3} ${styles.b8}`}></span>
      </p>
      <p className={`${styles.b1}`}>
        <span className={`${styles.b3} ${styles.b12}`}>Our Policy</span>
        <span className={`${styles.b3}`}>:</span>
      </p>
      <p className={`${styles.b1} ${styles.b5}`}>
        <span className={`${styles.b3} ${styles.b8}`}></span>
      </p>
      <p className={`${styles.b1}`}>
        <span>
          The purpose of these Privacy Terms is to describe how Zealthlife Pte
          Ltd. provides TrillionSale, a sales automation platform that would
          have seamless integration with CRM and communication tools.
          TrillionSale software would automatically scrape the internet,
          identify potential opportunities and draft personalised messages based
          on engagement with them. Additionally it will update your CRM, provide
          your sales team with personalized training and help with revenue
          forecasting. The TrillionSale
        </span>
        <span className={`${styles.b0}`}>Web Application</span>
        <span className={`${styles.b0}`}>&nbsp;</span>
        <span>(the &quot;</span>
        <span className={`${styles.b3}`}>Web App</span>
        <span>&quot;)</span>
        <span className={`${styles.b0}`}>
          &nbsp;for the sales executive and the managerial team for quick access
          and reminders{" "}
        </span>
        <span>operated by </span>
        <span className={`${styles.b7} ${styles.b0}`}>Zealthlife Pte Ltd</span>
        <span>. </span>
        <span>(&quot;</span>
        <span className={`${styles.b3}`}>TrillionSale</span>
        <span>
          &quot;, &quot;we&quot;, &quot;us&quot; and/or &quot;our&quot;). In
          addition to making available the Web Application, the TrillionSale
          website (the &quot;
        </span>
        <span className={`${styles.b3}`}>Site</span>
        <span>&quot;) is operated by</span>
        <span className={`${styles.b7} ${styles.b0}`}>Zealthlife Pte Ltd</span>
        <span>.</span>
        <span>&nbsp;</span>
        <span>
          and has been created to provide information about our company and the
          services we offer, including the Web App and any other web
          applications we make available and related services (collectively, the
          &quot;
        </span>
        <span className={`${styles.b3}`}>Services</span>
        <span>
          &quot;) to our Service visitors and users (&quot;you&quot;,
          &quot;your&quot;). This Privacy Policy sets forth TrillionSale&#39;s
          policy with respect to information including personally identifiable
          data (&quot;
        </span>
        <span className={`${styles.b3}`}>Personal Data</span>
        <span className={`${styles.b2}`}>
          &quot;) and other information that is collected on or in connection
          with the Services, including our chatbot service.
        </span>
      </p>

      <p className={`${styles.b1} ${styles.b5}`}>
        <span className={`${styles.b2}`}></span>
      </p>
      <p className={`${styles.b1}`}>
        <span className={`${styles.b2}`}>
          TrillionSale is committed to maintaining the confidentiality,
          integrity and security of the Personal Data provided by our users. We
          are not in the business of selling your Personal Data to third
          parties.
        </span>
      </p>
      <p className={`${styles.b1} ${styles.b5}`}>
        <span className={`${styles.b2}`}></span>
      </p>
      <p className={`${styles.b1} ${styles.b5}`}>
        <span className={`${styles.b2}`}></span>
      </p>
      <p className={`${styles.b1}`}>
        <span className={`${styles.b12} ${styles.b3}`}>Your Personal Data</span>
        <span className={`${styles.b8} ${styles.b3}`}>:</span>
      </p>
      <p className={`${styles.b1} ${styles.b5}`}>
        <span className={`${styles.b8} ${styles.b3}`}></span>
      </p>
      <p className={`${styles.b1}`}>
        <span className={`${styles.b2}`}>
          We believe that you own your Personal Data, and we enable you to keep
          your Personal Data accurate, current, and complete, by updating your
          Personal Data in the Web App whenever appropriate or by contacting us
          as specified below. We will take reasonable steps to update or correct
          your Personal Data in our possession that you have previously
          submitted.
        </span>
      </p>
      <p className={`${styles.b1} ${styles.b5}`}>
        <span className={`${styles.b2}`}></span>
      </p>
      <p className={`${styles.b1}`}>
        <span className={`${styles.b2}`}>
          You may also request that we terminate your account and delete your
          Personal Data at any time. When you make such a request, your Personal
          Data will be permanently expunged from our primary production servers
          and further access to your account will not be possible.
        </span>
      </p>
      <p className={`${styles.b1} ${styles.b5}`}>
        <span className={`${styles.b2}`}></span>
      </p>
      <p className={`${styles.b1} ${styles.b5}`}>
        <span className={`${styles.b2}`}></span>
      </p>

      <p className={`${styles.b1}`}>
        <span className={`${styles.b4} ${styles.b3}`}>
          Information We Collect:
        </span>
      </p>
      <p className={`${styles.b1} ${styles.b5}`}>
        <span className={`${styles.b2}`}></span>
      </p>
      <p className={`${styles.b1}`}>
        <span className={`${styles.b2}`}>
          When you interact with us through the Services, we may collect
          Personal Data and other information from you, as further described
          below:
        </span>
      </p>
      <p className={`${styles.b1} ${styles.b5}`}>
        <span className={`${styles.b2}`}></span>
      </p>
      <p className={`${styles.b1}`}>
        <span className={`${styles.b3}`}>
          Personal Data That You Provide Through the Services:
        </span>
        <span className={`${styles.b2}`}>
          We collect Personal Data/contact information from you when you
          voluntarily provide such information, such as when you contact us with
          inquiries, register for access to the TrillionSale Web Application,
          select or enter contact information on the Web Application, respond to
          one of our prompts, or use certain Services.
        </span>
        <span>For instance, during the set up process for the</span>
        <span>organisation</span>
        <span>
          , TrillionSale may collect account related data for each user such as
          contact information (e.g. name, address, phone number, email address,
          WhatsApp/Slack/Microsoft Teams avatar, Time zone and employer) and
          payment details for such accounts. We may use this data to send you
          information regarding our products and features for the services
          available to you.
        </span>
      </p>
      <p className={`${styles.b1} ${styles.b5}`}>
        <span className={`${styles.b2}`}></span>
      </p>
      <p className={`${styles.b1}`}>
        <span>
        Data obtained through the Google Workspace API and Outlook API is not used to develop, improve, or train generalized AI or machine learning models. Also, we do not store any data from a user&#39;s Gmail, Outlook,
          Slack,
        </span>
        <span>WhatsApp</span>
        <span className={`${styles.b2}`}>
          &nbsp;or Microsoft Teams instance. The only information that is stored
          is the user who integrated Slack/Microsoft Teams / G Suite / Outlook
          and the auth token. Both are deleted when the integration is removed
          from within our app.
        </span>
      </p>
      <p className={`${styles.b1} ${styles.b5}`}>
        <span className={`${styles.b2}`}></span>
      </p>
      <p className={`${styles.b1}`}>
        <span className={`${styles.b3}`}>
          Information collected by account owners for their customers:
        </span>
        <span className={`${styles.b2}`}>
          Account owners own all their Business and Customer Data. Depending on
          how the Account Owner and their Authorized Users choose to use the
          software, Customer Data may include certain personal information
          relating to the customers and clients of the Account Owner. Account
          Owners manage all Customer Data, as well as the users who create,
          manage, distribute, or report any Business Data generated by the
          Services. TrillionSale acts as a conduit between different platforms
          and does not save any data from HubSpot, Salesforce, Slack, Microsoft
          Teams and Google Calendar for any account unless specifically
          requested by the account owner for performing certain analytical
          functions as a part of our Services.
        </span>
      </p>
      <p className={`${styles.b1} ${styles.b5}`}>
        <span className={`${styles.b2}`}></span>
      </p>
      <p className={`${styles.b1}`}>
        <span>
          Even though you integrate your Google Calendar or Email with
          TrillionSale, we will not share your calendar events or email with any
          third parties. If your Google Calendar is integrated, we may store the
          minimal amount of data to be able to display calendar event data to
          you as a part of your enabled workflow(s).
        </span>
      </p>
      <p className={`${styles.b1} ${styles.b5}`}>
        <span className={`${styles.b2}`}></span>
      </p>

      <p className={`${styles.b1}`}>
        <span className={`${styles.b8} ${styles.b3}`}>
          By voluntarily providing us with Personal Data, you are consenting to
          our use of it in accordance with this Privacy Policy. If you provide
          Personal Data to the Services, you acknowledge and agree that such
          Personal Data may be transferred from your current location to the
          offices and servers of Zealthlife and the authorized third parties
          referred to herein located in the United States.
        </span>
      </p>
      <p className={`${styles.b1} ${styles.b5}`}>
        <span className={`${styles.b2}`}></span>
      </p>
      <p className={`${styles.b1} ${styles.b5}`}>
        <span className={`${styles.b2}`}></span>
      </p>
      <p className={`${styles.b1}`}>
        <span className={`${styles.b8} ${styles.b3}`}>Other Information:</span>
      </p>
      <p className={`${styles.b1} ${styles.b5}`}>
        <span className={`${styles.b2}`}></span>
      </p>
      <p className={`${styles.b1}`}>
        <span className={`${styles.b3}`}>Non-Identifiable Data:</span>
        <span className={`${styles.b2}`}>
          &nbsp;When you interact with TrillionSale through the Services, we
          receive and store certain personally non-identifiable information.
          TrillionSale may store such information itself or such information may
          be included in databases owned and maintained by our affiliates,
          agents or service providers. The Services may use such information and
          pool it with other information to track, for example, the total number
          of visitors to our Site, the number of visitors to each page of our
          Site, and the domain names of our visitors Internet service providers.
          It is important to note that no Personal Data is available or used in
          this process.
        </span>
      </p>
      <p className={`${styles.b1} ${styles.b5}`}>
        <span className={`${styles.b2}`}></span>
      </p>
      <p className={`${styles.b1}`}>
        <span className={`${styles.b2}`}>
          &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; In operating the Services,
          we may use a technology called &quot;cookies.&quot; A cookie is a
          piece of information that the computer that hosts our Services gives
          to your browser when you access the Services. Our cookies help provide
          additional functionality to the Services and help us analyze Services
          usage more accurately. For instance, our Site may set a cookie on your
          browser that allows you to access the Services without needing to
          remember and then enter a password more than once during a visit to
          the Site. In all cases in which we use cookies, we will not collect
          Personal Data except with your permission. On most web browsers, you
          will find a &ldquo;help&rdquo; section on the toolbar. Please refer to
          this section for information on how to receive notifications when you
          are receiving a new cookie and how to turn cookies off. We recommend
          that you leave cookies turned on because they allow you to take
          advantage of some of the Service features.
        </span>
      </p>
      <p className={`${styles.b1} ${styles.b5}`}>
        <span className={`${styles.b2}`}></span>
      </p>
      <p className={`${styles.b1} ${styles.b5}`}>
        <span className={`${styles.b2}`}></span>
      </p>
      <p className={`${styles.b1}`}>
        <span className={`${styles.b3}`}>
          Aggregated or De-Identified Personal Data:
        </span>
        <span className={`${styles.b2}`}>
          &nbsp;In an ongoing effort to better understand and serve the users of
          the Services, TrillionSale often conducts research on its customer
          demographics, interests and behaviour based on the Personal Data and
          other information provided to us. This research may be compiled and
          analyzed on an aggregated and/or de-identified basis, and we may share
          this aggregated and/or de-identified data with our affiliates, agents
          and business partners in order to advance research related to sales
          automation innovation or improve access to related services.
          TrillionSale may also disclose aggregated and/or de-identified user
          statistics and other metrics concerning our Sales Automation Services
          to describe our offerings to current and prospective business
          partners, and to other third parties and for other lawful purposes.
          For clarity, any data that has been aggregated and/or de-identified
          shall not be considered Personal Data under this Privacy Policy.
        </span>
      </p>
      <p className={`${styles.b1} ${styles.b5}`}>
        <span className={`${styles.b2}`}></span>
      </p>
      <p className={`${styles.b1} ${styles.b5}`}>
        <span className={`${styles.b2}`}></span>
      </p>
      <p className={`${styles.b1}`}>
        <span className={`${styles.b3}`}>Location Information:</span>
        <span className={`${styles.b2}`}>
          &nbsp;Our Service may collect and use your location information to
          provide certain functionality of our Service. If you choose to enable
          certain location sharing features, your location information may be
          publicly displayed within the Service. Please keep in mind that other
          users can see this information about you, and they may use it or
          disclose it to other individuals or entities outside of our control
          and without your knowledge. Your location information may be subject
          to abuse, misuse, and monitoring by others, so please be careful if
          you choose to enable location functionality. We may also use your
          location information in an aggregate way, as described above in the
          &quot;Aggregated Personal Data&quot; section.
        </span>
      </p>
      <p className={`${styles.b1} ${styles.b5}`}>
        <span className={`${styles.b2}`}></span>
      </p>
      <p className={`${styles.b1} ${styles.b5}`}>
        <span className={`${styles.b2}`}></span>
      </p>
      <p className={`${styles.b1}`}>
        <span className={`${styles.b3}`}>
          Analytics and Tracking Technologies:
        </span>
        <span>
          We may, and we may allow third party service providers to, use cookies
          (as noted above) or other tracking technologies to collect information
          about your browsing activities over time and across different websites
          following your use of the Site. For example, we use Google Analytics,
          a web analytics service provided by Google, Inc. (&quot;Google&quot;).
          Google Analytics uses cookies to help us analyze how users use the
          Site and enhance your experience when you use the Site. For more
          information on how Google uses this data, go to
        </span>
      </p>

      <p className={`${styles.b1} ${styles.b5}`}>
        <span className={`${styles.b2}`}></span>
      </p>
      <p className={`${styles.b1} ${styles.b5}`}>
        <span className={`${styles.b2}`}></span>
      </p>
      <p className={`${styles.b1}`}>
        <span className={`${styles.b3}`}>
          Analytics and Tracking Technologies:{" "}
        </span>
        <span>
          We may, and we may allow third party service providers to, use cookies
          (as noted above) or other tracking technologies to collect information
          about your browsing activities over time and across different websites
          following your use of the Site. For example, we use Google Analytics,
          a web analytics service provided by Google, Inc. (&quot;Google&quot;).
          Google Analytics uses cookies to help us analyze how users use the
          Site and enhance your experience when you use the Site. For more
          information on how Google uses this data, go to{" "}
        </span>
        <span className={`${styles.b10}`}>
          <a
            className={`${styles.b6}`}
            href="https://www.google.com/url?q=http://www.google.com/policies/privacy/partners/&amp;sa=D&amp;source=editors&amp;ust=1725370784171589&amp;usg=AOvVaw3wrobwTIF5X0rva3aWOaCr"
          >
            www.google.com/policies/privacy/partners/
          </a>
        </span>
        <span className={`${styles.b2}`}>
          . We also use Amplitude, which records certain events from user
          sessions that help us analyze how you use the App in order to improve
          the Services.
        </span>
      </p>
      <p className={`${styles.b1} ${styles.b5}`}>
        <span className={`${styles.b2}`}></span>
      </p>
      <p className={`${styles.b1}`}>
        <span className={`${styles.b2}`}>
          Our Site currently does not respond to &quot;Do Not Track&quot; (DNT)
          signals and operates as described in this Privacy Policy whether or
          not a DNT signal is received. If we do so in the future, we will
          describe how we do so in this Privacy Policy.
        </span>
      </p>
      <p className={`${styles.b1} ${styles.b5}`}>
        <span className={`${styles.b2}`}></span>
      </p>
      <p className={`${styles.b1} ${styles.b5}`}>
        <span className={`${styles.b2}`}></span>
      </p>
      <p className={`${styles.b1} ${styles.b5}`}>
        <span className={`${styles.b2}`}></span>
      </p>
      <p className={`${styles.b1}`}>
        <span className={`${styles.b4} ${styles.b3}`}>
          Our Use of Your Personal Data and Other Information:
        </span>
      </p>
      <p className={`${styles.b1}`}>
        <span>TrillionSale </span>
        <span className={`${styles.b2}`}>
          uses the Personal Data you provide in a manner that is consistent with
          this Privacy Policy. If you provide Personal Data for a certain
          reason, we may use the Personal Data in connection with the reason for
          which it was provided. For instance, if you contact us by email, we
          will use the Personal Data you provide to answer your question or
          resolve your problem. Also, if you provide Personal Data in order to
          obtain access to the Services, we will use your Personal Data to
          provide you with access to such services and to monitor your use of
          such services. TrillionSale and its subsidiaries and affiliates (the
          &quot;Related Companies&quot;) may also use your Personal Data and
          other personally non-identifiable information collected through the
          Services to help us improve the content and functionality of the
          Services, to better understand our users and to improve the Services.
          TrillionSale and its affiliates may use this information to contact
          you in the future to tell you about services we believe will be of
          interest to you. If we do so, each marketing communication we send you
          will contain instructions permitting you to &quot;opt-out&quot; of
          receiving future marketing communications. In addition, if at any time
          you wish not to receive any future marketing communications or you
          wish to have your name deleted from our mailing lists, please contact
          us as indicated below.{" "}
        </span>
      </p>
      <p className={`${styles.b1} ${styles.b5}`}>
        <span className={`${styles.b2}`}></span>
      </p>
      <p className={`${styles.b1}`}>
        <span className={`${styles.b2}`}>
          Certain other ways we may use your Personal Data are as follows:
        </span>
      </p>
      <p className={`${styles.b1} ${styles.b5}`}>
        <span className={`${styles.b2}`}></span>
      </p>
      <ul
        className={`${styles.b14} ${styles.lstKixZ99sfcszf4il0} ${styles.start}`}
      >
        <li className={`${styles.b1} ${styles.b9} ${styles.liBullet0}`}>
          <span className={`${styles.b2}`}>
            • To personalize your engagement with the Services and to allow us
            to deliver the type of content in which you are most interested.
          </span>
        </li>
        <li className={`${styles.b1} ${styles.b9} ${styles.liBullet0}`}>
          <span className={`${styles.b2}`}>
            • To quickly process your transactions.
          </span>
        </li>
        <li className={`${styles.b1} ${styles.b9} ${styles.liBullet0}`}>
          <span className={`${styles.b2}`}>
            • To verify and validate your identity.
          </span>
        </li>
        <li className={`${styles.b1} ${styles.b9} ${styles.liBullet0}`}>
          <span className={`${styles.b2}`}>
            • To administer a promotion, survey or other Service feature.
          </span>
        </li>
        <li className={`${styles.b1} ${styles.b9} ${styles.liBullet0}`}>
          <span className={`${styles.b2}`}>
            • To troubleshoot problems with the Services, or any services, as
            requested.
          </span>
        </li>
        <li className={`${styles.b1} ${styles.b9} ${styles.liBullet0}`}>
          <span>• To enforce the Service </span>
          <span className={`${styles.b10}`}>
            <a
              className={`${styles.b6}`}
              href="https://www.google.com/url?q=https://trillionsale.com/terms.pdf&amp;sa=D&amp;source=editors&amp;ust=1725370784173985&amp;usg=AOvVaw1d605gdmogGkCIsHkrgy9U"
            >
              Terms of Service
            </a>
          </span>
          <sup>
            <a href="#cmnt1" id="cmnt_ref1">
              [a]
            </a>
          </sup>
          <span className={`${styles.b2}`}>
            , and to detect and protect against error, fraud and other
            unauthorized or illegal activities.{" "}
          </span>
        </li>
        <li className={`${styles.b1} ${styles.b9} ${styles.liBullet0}`}>
          <span className={`${styles.b2}`}>
            • To attempt to contact you regarding safety issues.
          </span>
        </li>
        <li className={`${styles.b1} ${styles.b9} ${styles.liBullet0}`}>
          <span className={`${styles.b2}`}>
            • To provide any legitimate business service or Product.
          </span>
        </li>
      </ul>
      <p className={`${styles.b1} ${styles.b5}`}>
        <span className={`${styles.b2}`}></span>
      </p>
      <p className={`${styles.b1} ${styles.b5}`}>
        <span className={`${styles.b2}`}></span>
      </p>
      <p className={`${styles.b1} ${styles.b5}`}>
        <span className={`${styles.b2}`}></span>
      </p>
      <p className={`${styles.b1}`}>
        <span className={`${styles.b4} ${styles.b3}`}>
          Our Disclosure of Your Personal Data and Other Information:
        </span>
      </p>
      <p className={`${styles.b1}`}>
        <span className={`${styles.b2}`}>
          We consider your Personal Data to be a vital part of our relationship
          with you. There are, however, certain circumstances in which we may
          share your Personal Data with certain third parties without further
          notice to you, as set forth below:
        </span>
      </p>
      <p className={`${styles.b1} ${styles.b5}`}>
        <span className={`${styles.b2}`}></span>
      </p>
      <p className={`${styles.b1}`}>
        <span className={`${styles.b3}`}>Your Express Authorization:</span>
        <span className={`${styles.b2}`}>
          &nbsp;In specific instances, we may share your Personal Data with
          third parties when we have obtained your explicit and informed
          consent. This authorization will be sought through clear and
          transparent means, such as consent forms or opt-in mechanisms,
          ensuring that you are fully aware of the nature and purpose of the
          data sharing. By providing your express authorization, you acknowledge
          and consent to the sharing of your Personal Data for the specified
          purposes outlined at the time of obtaining your consent. Please be
          assured that your consent is entirely voluntary, and you have the
          right to revoke it at any time, subject to the terms of our Privacy
          Policy.
        </span>
      </p>
      <p className={`${styles.b1} ${styles.b5}`}>
        <span className={`${styles.b2}`}></span>
      </p>
      <p className={`${styles.b1}`}>
        <span className={`${styles.b3}`}>Business Transfers:</span>
        <span className={`${styles.b2}`}>
          &nbsp;As we develop our business, we might sell or buy businesses or
          assets. In the event of a corporate sale, merger, reorganization,
          dissolution or similar event, Personal Data may be part of the
          transferred assets.
        </span>
      </p>
      <p className={`${styles.b1} ${styles.b5}`}>
        <span className={`${styles.b2}`}></span>
      </p>
      <p className={`${styles.b1}`}>
        <span className={`${styles.b3}`}>Related Companies:</span>
        <span className={`${styles.b2}`}>
          &nbsp;We may also share your Personal Data with our Related Companies
          for purposes consistent with this Privacy Policy.
        </span>
      </p>
      <p className={`${styles.b1} ${styles.b5}`}>
        <span className={`${styles.b2}`}></span>
      </p>
      <p className={`${styles.b1}`}>
        <span className={`${styles.b3}`}>
          Agents, Consultants and Related Third Parties:
        </span>
        <span className={`${styles.b2}`}>
          &nbsp;TrillionSale, like many businesses, sometimes hires other
          companies to perform certain business-related functions. Examples of
          such functions include mailing information, maintaining databases and
          processing payments. When we employ another entity to perform a
          function of this nature, we only provide them with the information
          that they need to perform their specific function.
        </span>
      </p>
      <p className={`${styles.b1} ${styles.b5}`}>
        <span className={`${styles.b2}`}></span>
      </p>
      <p className={`${styles.b1}`}>
        <span className={`${styles.b3}`}>Legal Requirements:</span>
        <span className={`${styles.b2}`}>
          &nbsp;TrillionSale may disclose your Personal Data if required to do
          so by law or in the good faith belief that such action is necessary to
          (i) comply with a legal obligation, (ii) protect and defend the rights
          or property of TrillionSale, (iii) act in urgent circumstances to
          protect the personal safety of users of the Services or the public, or
          (iv) protect against legal liability.
        </span>
      </p>
      <p className={`${styles.b1} ${styles.b5}`}>
        <span className={`${styles.b4} ${styles.b3}`}></span>
      </p>
      <p className={`${styles.b1} ${styles.b5}`}>
        <span className={`${styles.b4} ${styles.b3}`}></span>
      </p>
      <p className={`${styles.b1}`}>
        <span className={`${styles.b4} ${styles.b3}`}>Your Choices:</span>
      </p>
      <p className={`${styles.b1}`}>
        <span className={`${styles.b2}`}>
          You can visit the Site without providing any Personal Data. If you
          choose not to provide any Personal Data, you may not be able to use
          certain Services.
        </span>
      </p>

      <p className={`${styles.b1} ${styles.b5}`}>
        <span className={`${styles.b2}`}></span>
      </p>
      <p className={`${styles.b1} ${styles.b5}`}>
        <span className={`${styles.b2}`}></span>
      </p>
      <p className={`${styles.b1} ${styles.b5}`}>
        <span className={`${styles.b2}`}></span>
      </p>
      <p className={`${styles.b1}`}>
        <span className={`${styles.b4} ${styles.b3}`}>Exclusions:</span>
      </p>
      <p className={`${styles.b1}`}>
        <span className={`${styles.b2}`}>
          This Privacy Policy does not apply to any Personal Data collected by
          TrillionSale other than Personal Data collected through the Services.
          This Privacy Policy shall not apply to any unsolicited information you
          provide to TrillionSale through the Services or through any other
          means. This includes, but is not limited to, information posted to any
          public areas of the Services, such as forums, any ideas for new
          products or modifications to existing products, and other unsolicited
          submissions (collectively, "Unsolicited Information"). All Unsolicited
          Information shall be deemed to be non-confidential and shall be free
          to reproduce, use, disclose, and distribute such Unsolicited
          Information to others without limitation or attribution.
        </span>
      </p>
      <p className={`${styles.b1} ${styles.b5}`}>
        <span className={`${styles.b2}`}></span>
      </p>
      <p className={`${styles.b1} ${styles.b5}`}>
        <span className={`${styles.b2}`}></span>
      </p>
      <p className={`${styles.b1} ${styles.b5}`}>
        <span className={`${styles.b2}`}></span>
      </p>
      <p className={`${styles.b1}`}>
        <span className={`${styles.b4} ${styles.b3}`}>Children:</span>
      </p>
      <p className={`${styles.b1}`}>
        <span>
          TrillionSale does not knowingly collect Personal Data from children
          under the age of 13. If you are under the age of 13, please do not
          submit any Personal Data through the Services. We encourage parents
          and legal guardians to monitor their children’s Internet usage and to
          help enforce our Privacy Policy by instructing their children never to
          provide Personal Data on the Services without their permission. If you
          have reason to believe that a child under the age of 13 has provided
          Personal Data to TrillionSale through the Services, please contact us,
          and we will endeavor to delete that information from our databases.
        </span>
      </p>
      <p className={`${styles.b1} ${styles.b5}`}>
        <span className={`${styles.b2}`}></span>
      </p>
      <p className={`${styles.b1} ${styles.b5}`}>
        <span className={`${styles.b2}`}></span>
      </p>
      <p className={`${styles.b1}`}>
        <span className={`${styles.b4} ${styles.b3}`}>
          Links to Other Web Sites:
        </span>
      </p>
      <p className={`${styles.b1}`}>
        <span className={`${styles.b2}`}>
          This Privacy Policy applies only to the Services. The Services may
          contain links to other web sites not operated or controlled by
          TrillionSale ("Third Party Sites"). The policies and procedures we
          described here do not apply to the Third Party Sites. The links from
          the Services do not imply that TrillionSale endorses or has reviewed
          the Third Party Sites. We suggest contacting those sites directly for
          information on their privacy policies.
        </span>
      </p>
      <p className={`${styles.b1} ${styles.b5}`}>
        <span className={`${styles.b2}`}></span>
      </p>
      <p className={`${styles.b1} ${styles.b5}`}>
        <span className={`${styles.b2}`}></span>
      </p>
      <p className={`${styles.b1} ${styles.b5}`}>
        <span className={`${styles.b2}`}></span>
      </p>
      <p className={`${styles.b1}`}>
        <span className={`${styles.b4} ${styles.b3}`}>Security:</span>
      </p>
      <p className={`${styles.b1}`}>
        <span>TrillionSale</span>
        <span>&nbsp;</span>
        <span className={`${styles.b2}`}>
          takes commercially reasonable steps to protect the Personal Data
          provided via the Services from loss, misuse, and unauthorized access,
          disclosure, alteration, or destruction. For example, access to any
          Personal Data you provide is restricted and used in accordance with
          this Privacy Policy. The vendors and service providers with whom we
          engage have been selected in accordance with our security policies and
          practices and are bound by confidentiality obligations. They may be
          subject to discipline, including termination and criminal prosecution,
          if they fail to meet these obligations. We also use a combination of
          firewall barriers, encryption techniques and authentication
          procedures, among others, designed to maintain the security of your
          online session and to protect TrillionSale accounts and systems from
          unauthorized access. However, no Internet or email transmission is
          ever fully secure or error free. In particular, email sent to or from
          the Services may not be secure. Therefore, you should take special
          care in deciding what information you send to us via email. Please
          keep this in mind when disclosing any Personal Data to TrillionSale
          via the Internet.
        </span>
      </p>
      <p className={`${styles.b1} ${styles.b5}`}>
        <span className={`${styles.b4} ${styles.b3}`}></span>
      </p>
      <p className={`${styles.b1} ${styles.b5}`}>
        <span className={`${styles.b4} ${styles.b3}`}></span>
      </p>
      <p className={`${styles.b1} ${styles.b5}`}>
        <span className={`${styles.b4} ${styles.b3}`}></span>
      </p>
      <p className={`${styles.b1}`}>
        <span className={`${styles.b12} ${styles.b3}`}>
          Other Terms and Conditions:
        </span>
        <span className={`${styles.b2}`}>&nbsp;</span>
      </p>
      <p className={`${styles.b1}`}>
        <span className={`${styles.b2}`}>
          Your access to and use of the Services is subject to the Terms of
          Service at
        </span>
      </p>
      <p className={`${styles.b1}`}>
        <span className={`${styles.b10}`}>
          <a
            className={`${styles.b6}`}
            href="https://www.google.com/url?q=https://trillionsale.com/terms.pdf&amp;sa=D&amp;source=editors&amp;ust=1725370784179306&amp;usg=AOvVaw36FBkjngaqIPnF4ZUJQ2EM"
          >
            https://trillionsale.com/terms.pdf
          </a>
        </span>
        <sup>
          <a href="#cmnt2" id="cmnt2">
            [b]
          </a>
        </sup>
      </p>
      <p className={`${styles.b1} ${styles.b5}`}>
        <span className={`${styles.b2}`}></span>
      </p>
      <p className={`${styles.b1} ${styles.b5}`}>
        <span className={`${styles.b2}`}></span>
      </p>
      <p className={`${styles.b1}`}>
        <span className={`${styles.b4} ${styles.b3}`}>
          Changes to Our Privacy Policy:
        </span>
      </p>
      <p className={`${styles.b1} ${styles.b5}`}>
        <span className={`${styles.b2}`}></span>
      </p>
      <p className={`${styles.b1}`}>
        <span className={`${styles.b2}`}>
          The Services and our business may change from time to time. As a
          result, at times it may be necessary for TrillionSale to make changes
          to this Privacy Policy. TrillionSale reserves the right to update or
          modify this Privacy Policy at any time and from time to time without
          prior notice. Please review this policy periodically, and especially
          before you provide any Personal Data. This Privacy Policy was last
          updated on the date indicated above. Your continued use of the
          Services after any changes or revisions to this Privacy Policy shall
          indicate your agreement with the terms of such revised Privacy Policy.
        </span>
      </p>
      <p className={`${styles.b1} ${styles.b5}`}>
        <span className={`${styles.b2}`}></span>
      </p>
      <p className={`${styles.b1} ${styles.b5}`}>
        <span className={`${styles.b2}`}></span>
      </p>
      <p className={`${styles.b1}`}>
        <span className={`${styles.b4} ${styles.b3}`}>Governing Law:</span>
      </p>
      <p className={`${styles.b1}`}>
        <span className={`${styles.b2}`}>
          The Privacy Terms shall be governed by the laws of Singapore and the
          courts of
        </span>
      </p>
      <p className={`${styles.b1}`}>
        <span className={`${styles.b2}`}>
          Singapore shall have exclusive jurisdiction.
        </span>
      </p>

      <p className={`${styles.b1} ${styles.b5}`}>
        <span className={`${styles.b2}`}></span>
      </p>
      <p className={`${styles.b1} ${styles.b5}`}>
        <span className={`${styles.b2}`}></span>
      </p>
      <p className={`${styles.b1}`}>
        <span className={`${styles.b3} ${styles.b4}`}>
          Access to Information; Contacting Us:
        </span>
      </p>
      <p className={`${styles.b1} ${styles.b5}`}>
        <span className={`${styles.b2}`}></span>
      </p>
      <p className={`${styles.b1}`}>
        <span className={`${styles.b2}`}>
          Please feel free to contact us if you have any questions about our
          Privacy Policy or our information practices.
        </span>
      </p>
      <p className={`${styles.b1} ${styles.b5}`}>
        <span className={`${styles.b2}`}></span>
      </p>
      <p className={`${styles.b1}`}>
        <span className={`${styles.b2}`}>You may contact us as follows:</span>
      </p>
      <p className={`${styles.b1}`}>
        <span className={`${styles.b10}`}>
          <a className={`${styles.b6}`} href="mailto:privacy@trillionsale.com">
            privacy@trillionsale.com
          </a>
        </span>
      </p>
      <div>
        <p className={`${styles.b11} ${styles.b5}`}>
          <span className={`${styles.b2}`}></span>
        </p>
      </div>
    </div>
  );
};

export default Privacy;
